import React from "react";

export default function Navbar() {
  return (
    <div className="flex w-full items-center justify-between border-b border-red-100 flex-grow-0 flex-shrink-0 h-16 mb-12">
      <h2 className="text-red-600 font-bold text-3xl">dateme.fyi</h2>
      {/* <div className="flex flex-row gap-1">
        <Login />
        <Signup />
      </div> */}
    </div>
  );
}

function Login() {
  return (
    <div className="rounded bg-red-600 text-white font-bold text-xl px-3 py-2 hover:cursor-pointer">
      Login
    </div>
  );
}

function Signup() {
  return (
    <div className="rounded bg-red-600 text-white font-bold text-xl px-3 py-2 hover:cursor-pointer">
      Signup
    </div>
  );
}
