import React from "react";

export default function LandingPage() {
  return (
    <div>
      <h1 className="text-6xl font-boldtext-red-600 mb-12">
        Be your whole self
      </h1>
      <p className="text-xl">
        <span className="font-bold">dateme.fyi</span> lets you create a
        completely unique profile where you can write down whatever you want and
        share it with your matches using an easy-to-remember, password-protected
        link.
      </p>
      {/* <h4>Todo:</h4>
      <ul className="text-left">
        <li>Branding</li>
        <li>Basic design, look & feel</li>
        <li>Login / signup buttons show auth modal</li>
        <li>Capture phone on sign up</li>
        <li>Redirect all routes to blank password protected page</li>
        <li>Everything looks good on mobile</li>
      </ul> */}
    </div>
  );
}
