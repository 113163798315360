import React from "react";

export default function PasswordProtected({
  onChangePassword,
}: {
  onChangePassword: (text: string) => void;
}) {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="flex flex-col">
        <h1 className="text-3xl text-red-600 mb-2">Enter password to view</h1>
        <input
          placeholder="password"
          onChange={(ev) => {
            onChangePassword(ev.target.value);
          }}
        />
      </div>
    </div>
  );
}
