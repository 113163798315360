import React from "react";
import "./App.css";
import LandingPage from "./pages/landing";
import Will from "./pages/will";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DefaultPage from "./pages/default";
import Navbar from "./components/Navbar";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/will",
    element: <Will />,
  },
  {
    path: "*",
    element: <DefaultPage />,
  },
]);

function App() {
  return (
    <div className="flex items-center flex-col px-12 h-screen">
      <div className="flex flex-col w-full max-w-7xl grow">
        <Navbar />
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
