import React, { useState } from "react";
import PasswordProtected from "../components/PasswordProtected";

const ACCEPTED_PASSWORDS = ["test"];

function ProfilePoint({
  heading,
  children,
}: {
  heading: string;
  children: React.ReactNode;
}) {
  return (
    <p className="mb-6">
      <span className="font-bold">{heading}</span>: {children}
    </p>
  );
}

export default function Will() {
  const [password, setPassword] = useState("");
  return ACCEPTED_PASSWORDS.includes(password) ? (
    <div>
      <h4 className="text-2xl font-bold mb-4">
        Some things you should know about me
      </h4>
      <ProfilePoint heading="Kids">75% chance I want them.</ProfilePoint>
      <ProfilePoint heading="Monogamy">
        Monogamish. I don’t place a lot of value on sexual exclusivity. I do
        place a lot of value on developing real emotional intimacy and building
        a life together. My ideal is to be generally monogamous while
        maintaining the possibility of sexual experiences with others, either
        separate or together. Of course, all of this requires great
        communication and lots of trust and is not something I would do in the
        beginning of a relationship while we’re getting to know each other.
      </ProfilePoint>
      <ProfilePoint heading="Sexuality">
        Bicurious / heteroflexible - definitely into women, sometimes into men.
        Something I’m still exploring. I tend to like more feminine physical
        features but I don’t have any requirements around your sexuality or
        gender expression — you do you.
      </ProfilePoint>
      <ProfilePoint heading="Sex">
        Sex to me is something joyous and fun and comes easy. I have a high
        libido. I understand that sex in relationships ebbs and flows and I
        don’t ever want to have sex if someone doesn’t want to or feel like it —
        if my partner isn’t feeling up for it then it doesn’t happen and it’s
        totally fine. I’m a strong believer in quality over quantity. Most
        important in sex is the intimacy that it brings in the context of a
        relationship. Generally very open to new experiences.
      </ProfilePoint>
      <ProfilePoint heading="Family">
        I keep a healthy distance from my parents. I’m close with my brother who
        lives on the west coast. I have no expectations that my partner gets
        along with my family or ever wants to make my family their own. It’s
        great if they do but it’s not something I would ever force. I expect the
        same from my partner and to live an independent life from their family
        (unless I organically want otherwise).
      </ProfilePoint>
      <ProfilePoint heading="Health">
        I’m generally very healthy: I eat well and I exercise a lot. I love to
        work out and be active. My only real health problem is my ears: I have
        chronic Eustachian tube dysfunction, which means the tiny tube that
        connects your middle ear to your throat and equalizes pressure doesn’t
        work very well for me. Both ears have it but my right ear is especially
        problematic. I’ve had a disease called cholesteatoma three times now in
        my right ear which has impacted my hearing and I have prosthetic ear
        bones in my right ear. I can generally still hear pretty well
        (especially considering what’s happened) but you’ll see me lean in with
        my left ear if I can’t hear something. I always prefer people to
        sit/stand to the left of me when talking but it’s not a hard
        requirement. I probably won’t ever be able to go sky diving or scuba
        diving which is a damn shame. There’s a chance my hearing could get
        significantly worse in my lifetime.
      </ProfilePoint>
      <ProfilePoint heading="Mental Health">
        I have complex PTSD, which is a form of PTSD that comes from prolonged
        abuse and emotional neglect as a child. I’ve done a ton of work on it
        and it doesn’t impact me that greatly anymore. It’s a lifelong project
        and I’m committed to growth and healing and being in healthy
        relationships. I’m very good at identifying when I’m triggered and can
        almost always articulate my needs in the moment. I’ve been in and out of
        therapy for almost 15 years.
      </ProfilePoint>
      <ProfilePoint heading="Friends">
        Friends are my chosen family and I see them frequently. It’s important
        to me that my partner likes my friends on some basic level since I’m
        around them a lot. I don’t expect my friends to become their friends,
        though that’s cool if they do.
      </ProfilePoint>
      <ProfilePoint heading="Independence">
        I believe it’s important for people in a relationship to have their own
        lives and to not exclusively rely on each other for happiness and
        well-being, which I believe only comes from within. Our lives should be
        integrated to the extent that it’s fun and fulfilling and better than
        being solo. I don’t expect every aspect of our lives will be like that
        and that’s okay, though I do expect there to be some overlap. My ideal
        is to be in a relationship where we support each other to live the best
        versions of our lives — but we don’t live each other’s lives or become
        overresponsible for them.
      </ProfilePoint>
      <ProfilePoint heading="Prior relationships">
        I’m on good terms with almost all of my exes, some of whom are still in
        my life as friends. They’re all lovely people and I would never date or
        sleep with any of them again. Typically when a romantic relationship is
        done for me it’s totally done.
      </ProfilePoint>
      <ProfilePoint heading="Jealousy">
        I’m really not a jealous person. I don’t care if you’re friends with
        your exes, if you’re friends with someone who has a crush on you or if
        you have a crush on someone else. I don’t care if you flirt with other
        people. I do care if your crush on someone else means you don’t have a
        crush on me anymore, or if you want to spend most of your relationship
        time/energy with someone else. It’s important my partner feels
        similarly.
      </ProfilePoint>
      <ProfilePoint heading="Money">
        Money is a means to an end to me. I’m not materialistic and don’t spend
        a lot. I prefer to save for the future, which for me has mostly taken
        the form of saving a lot so I have a financial cushion to do speculative
        projects like startups. I don’t come from wealth and I take care of
        myself. I’m conservative with most of my investments though I take risk
        in other ways, eg startups. Every time I make a bet I make sure I have a
        solid reason to believe that the upside I’m expecting will materialize
        and that the downside is limited. I would never play a game with a
        negative expected value (eg casino games).
      </ProfilePoint>
      <ProfilePoint heading="How I handle conflict">
        I try very hard to be kind, direct and quick with my words and take a
        collaborative approach in solving issues. I’m generally not an angry
        person. It’s very important to me that feelings are out in the open and
        that we discuss things in the clear, even (and especially) when it’s
        inconvenient and it’s painful. I place a lot of value in listening.
      </ProfilePoint>
      {/* <h4>Todo</h4>
      <ul>
        <li>Profile content</li>
        <li>Sticky sign up banner</li>
      </ul> */}
    </div>
  ) : (
    <PasswordProtected onChangePassword={setPassword} />
  );
}
